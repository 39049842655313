import { createBlankEstimate, updateEstimateData } from "@/apps/calculator/api"
import { useLoading } from "@/composition/useLoading"
import { useService } from "@/composition/useService"
import type { CustomerEstimateData, Nullable } from "@/models"
import { reactive, type InjectionKey, type Ref } from "vue"
import type { ValidationHandler } from "../../composition/ValidationHandler"
import { Steps } from "./steps"
import { getStepInformation, type EstimateStep } from "./steps/types"

export const StepHandlerKey = Symbol("StepHandler") as InjectionKey<StepHandler>

export function useStepHandler() {
	return useService(StepHandlerKey)
}

export class StepHandler {
	private readonly estimateDataRef: Ref<CustomerEstimateData | null>
	private readonly formRef: Ref<HTMLFormElement | undefined>
	private readonly validationHandler: ValidationHandler

	constructor(
		estimateData: Ref<CustomerEstimateData | null>,
		formRef: Ref<HTMLFormElement | undefined>,
		validationHandler: ValidationHandler
	) {
		this.estimateDataRef = estimateData
		this.formRef = formRef
		this.validationHandler = validationHandler
	}

	get estimateData(): CustomerEstimateData {
		const data = this.estimateDataRef.value
		if (!data) {
			throw new Error("No estimate data has been loaded")
		}
		return data
	}

	set estimateData(estimateData: CustomerEstimateData | null) {
		if (estimateData === null) {
			this.estimateDataRef.value = null
		} else {
			const existing = this.estimateDataOrNull
			if (existing) {
				Object.assign(existing, estimateData)
			} else {
				this.estimateDataRef.value = reactive(estimateData)
			}
		}
		this.updateLocalStorage()
	}

	updateLocalStorage() {
		const data = this.estimateDataOrNull
		if (data) {
			localStorage.setItem("estimate-data", JSON.stringify(data))
		} else {
			localStorage.removeItem("estimate-data")
		}
	}

	get estimateDataOrNull(): Nullable<CustomerEstimateData> {
		return this.estimateDataRef.value
	}

	get hasEstimateData(): boolean {
		return this.estimateDataRef.value !== null
	}

	get currentStep(): Nullable<EstimateStep> {
		const data = this.estimateDataRef.value
		if (!data) {
			return null
		}
		const stepId = data.metadata.currentStep
		if (!stepId) {
			return null
		}
		const step = Object.values(Steps).find((step) => step.id === stepId)
		if (!step) {
			//TODO There's a step but its invalid. Just return null?
			return null
		}
		return step
	}

	set currentStep(step: EstimateStep) {
		this.estimateData.metadata.currentStep = step.id
		this.updateLocalStorage()
	}

	/**
	 * @returns The step after this one, or null if either there is no next step
	 */
	get nextStep(): Nullable<EstimateStep> {
		const current = this.currentStep
		if (!current) {
			return null
		}
		return getStepInformation(current.nextStep, this.estimateData)
	}

	get previousStep(): Nullable<EstimateStep> {
		const current = this.currentStep
		if (!current) {
			return null
		}
		return getStepInformation(current.previousStep, this.estimateData)
	}

	countNextSteps(): number {
		let step = this.currentStep
		let count = 0
		while (step) {
			step = getStepInformation(step.nextStep, this.estimateData)
			if (step) {
				count++
			}
		}
		return count
	}

	countPreviousSteps(): number {
		let step = this.currentStep
		let count = 0
		while (step) {
			step = getStepInformation(step.previousStep, this.estimateData)
			if (step) {
				count++
			}
		}
		return count
	}

	async loadInitialData() {
		const { runAction } = useLoading()
		const data = await runAction(createBlankEstimate())

		this.estimateData = data
		this.currentStep = Steps.InitialStep
	}

	/**
	 * @param nextStep The next step to go to. Leave undefined to automatically select the next step
	 * @returns A promise indicating whether the process was successful
	 */
	async goToNextStep(nextStep?: EstimateStep): Promise<boolean> {
		const form = this.formRef.value ?? null
		if (!(await this.validationHandler.validateForm(form))) {
			return false
		}

		const step = nextStep ?? this.nextStep
		if (!step) {
			await this.loadInitialData()
			return true
		}

		const { runAction } = useLoading()
		await runAction(this.runNextStepLogic(step))

		return true
	}

	goBack(previousStep?: EstimateStep) {
		const step = previousStep ?? this.previousStep
		if (!step || !this.estimateDataRef.value) {
			throw new Error("There is no previous step to go back to")
		}
		this.currentStep = step
	}

	private async runNextStepLogic(nextStep: EstimateStep) {
		const currentStepId = this.estimateData.metadata.currentStep

		const newData = await updateEstimateData(this.estimateData)
		this.estimateData = newData

		const newStepId = this.estimateData.metadata.currentStep

		//If the step was changed on the server during the action, ignore the natural next step
		if (currentStepId === newStepId) {
			this.currentStep = nextStep
		}
		this.updateLocalStorage()
	}
}
