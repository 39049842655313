import { onMounted, onUnmounted } from "vue"
import { useValidationHandler, type CustomValidation } from "./ValidationHandler"

/**
 * Registers a validation to be run when the current form is submitted.
 * Returning a string from the validation will show an error message to the user
 *
 * @param validation Validation to run
 */
export function useCustomValidation(validation: CustomValidation) {
	const handler = useValidationHandler()

	let symbol: symbol
	onMounted(() => (symbol = handler.addCustomValidation(validation)))
	onUnmounted(() => handler.removeCustomValidation(symbol))
}
