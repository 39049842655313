import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicStep from "../components/BasicStep"
import YesNoOption from "../components/YesNoOption"
import { css } from "vite-css-in-js"
import { useCustomerEstimate } from "../composition"

const listStyles = css`
	all: unset;
	li {
		all: unset;
	}

	display: grid;
	grid-template-columns: 1fr 1fr;

	gap: 1.5rem;
	width: 100%;

	@media (max-width: 48rem) {
		grid-template-columns: 1fr;
	}
`

const ReceivedBenefitsStep: ReactiveComponent = () => {
	const data = useCustomerEstimate()

	return () => (
		<BasicStep title="Received Benefits">
			<p>Do you receive any of the following? Select all that apply.</p>

			<ul class={listStyles}>
				{data.receivedBenefits.map((benefit) => (
					<li key={benefit.type}>
						<YesNoOption
							name={`benefit-${benefit.type.toLowerCase()}`}
							value={benefit.received}
							setValue={(v) => (benefit.received = v)}
							required
						>
							{benefit.displayName}?
						</YesNoOption>
					</li>
				))}
			</ul>
		</BasicStep>
	)
}

export default defineComponent(ReceivedBenefitsStep)
