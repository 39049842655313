import { shallowRef } from "vue"
import { ConfirmResult, defineComponent } from "vue-utils"
import BootstrapModal from "./BootstrapModal"
import type { AlertModalData, ConfirmModalData, ModalData, OpenModal } from "./types"

let modalsOpened = 0
const modals = shallowRef<OpenModal[]>([])

export function openModal(modal: ModalData) {
	modals.value = [
		...modals.value,
		{
			...modal,
			id: String(modalsOpened++),
		},
	]
}

export function showClosableConfirmModal(modal: ConfirmModalData): Promise<ConfirmResult> {
	let result = ConfirmResult.Closed

	return new Promise<ConfirmResult>((resolve) => {
		openModal({
			closeOnBackgroundClick: true,
			closeButton: false,
			...modal,
			onClosed() {
				resolve(result)
				modal.onClosed?.()
			},
			footer: ({ close }) => (
				<>
					<button
						type="button"
						class="btn btn-secondary"
						onClick={() => {
							result = ConfirmResult.Cancel
							close()
						}}
					>
						{modal.cancelText ?? "Cancel"}
					</button>
					<button
						type="button"
						class="btn btn-primary"
						onClick={() => {
							result = ConfirmResult.Confirm
							close()
						}}
					>
						{modal.confirmText ?? "Confirm"}
					</button>
				</>
			),
		})
	})
}

export async function showConfirmModal(modal: ConfirmModalData): Promise<boolean> {
	const result = await showClosableConfirmModal({
		closeOnBackgroundClick: false,
		closeButton: false,
		...modal,
	})
	return result === ConfirmResult.Confirm
}

export function showAlertModal(modal: AlertModalData): Promise<void> {
	return new Promise((resolve) => {
		openModal({
			...modal,
			onClosed() {
				resolve()
				modal.onClosed?.()
			},
			footer: ({ close }) => (
				<button type="button" class="btn btn-secondary" onClick={close}>
					{modal.closeText ?? "Close"}
				</button>
			),
		})
	})
}

export function showModalMessage(title: string, message: string) {
	return showAlertModal({
		title,
		content: message,
	})
}

export const ModalsWrapper = defineComponent({
	name: "ModalsWrapper",
	setup() {
		return () =>
			modals.value.map((modal, i) => (
				<BootstrapModal
					key={modal.id}
					closeOnBackgroundClick={modal.closeOnBackgroundClick}
					closeButton={modal.closeButton}
					title={modal.title}
					content={modal.content}
					footer={modal.footer}
					onClosed={() => {
						modals.value.splice(i, 1)
						modal.onClosed?.()
					}}
				/>
			))
	},
})
