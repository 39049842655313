export function fixPostcode(postcode: string): string {
	if (!isPostcodeValid(postcode)) {
		return postcode.toUpperCase()
	}
	postcode = postcode.replace(/\s/g, "")
	return `${postcode.substring(0, postcode.length - 3)} ${postcode.substring(postcode.length - 3)}`.toUpperCase()
}

export function isPostcodeValid(postcode: string): boolean {
	return /^[A-Z](\d|[A-Z]){1,3}\s?\d(\d|[A-Z]){2}$/gi.test(postcode.trim())
}
