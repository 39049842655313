import { PropertyOwnerStatus } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicStep from "../components/BasicStep"
import RadioButtons, { Orientation, type RadioOption } from "../components/RadioButtons"
import { useCustomerEstimate } from "../composition"

const PropertyOwnerStep: ReactiveComponent = () => {
	const estimate = useCustomerEstimate()
	const options = computed<RadioOption<PropertyOwnerStatus>[]>(() =>
		Object.values(PropertyOwnerStatus).map((status) => ({
			data: status,
			value: status,
			text: formatEnum(status),
		}))
	)

	return () => (
		<BasicStep title="About Your Property">
			<RadioButtons
				name="owner-status"
				orientation={Orientation.Portrait}
				options={options.value}
				value={estimate.ownerStatus}
				setValue={(status) => (estimate.ownerStatus = status)}
				required
			>
				<p>Please tell us what your relationship to your property is.</p>
				<p>I am a...</p>
			</RadioButtons>
		</BasicStep>
	)
}

export default defineComponent(PropertyOwnerStep)
