import { Apps } from "./apps"
import "./fonts"
import "vue-utils/dist/style.css"
import "./styles/main.scss"

//Only used for development
document.getElementById("temporary-stylesheet")?.remove()

document.addEventListener("DOMContentLoaded", () => {
	for (const appInfo of Apps) {
		try {
			const app = appInfo.tryRegister()
			if (app) {
				console.debug("Registered app", appInfo.name)
			}
		} catch (e) {
			console.error("Error loading app", appInfo.name)
		}
	}
})
