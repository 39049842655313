import { defineComponent, Required, type ReactiveComponent } from "vue-utils"
import BasicStep from "../components/BasicStep"
import { proxyProp } from "@/utils/proxyProp"
import { css } from "vite-css-in-js"
import { useCustomerEstimate } from "../composition"

const gridStyles = css`
	display: grid;
	grid-template-columns: auto auto;

	gap: 1.5rem;
	width: 100%;
	margin-top: 1.5rem;

	@media (max-width: 40rem) {
		grid-template-columns: auto;
		justify-content: stretch;
		align-items: center;
	}
`

const LandlordInformationStep: ReactiveComponent = () => {
	const data = useCustomerEstimate()
	const contactInformation = proxyProp(() => {
		let contact = data.contactInformation
		if (!contact) {
			contact = {
				name: "",
				email: "",
				mobileNumber: null,
			}
			data.contactInformation = contact
		}
		return contact
	})

	return () => (
		<BasicStep title="Landlord Information">
			<p>You have indicated you are a landlord. Please fill in your contact information.</p>
			<div class={gridStyles}>
				<label>
					<Required label="Your name" />
					<input type="text" placeholder="Please enter your name" v-model={contactInformation.name} required />
				</label>

				<label>
					<Required label="Your Email" />
					<input type="email" placeholder="Please enter your email" v-model={contactInformation.email} required />
				</label>

				<label>
					Mobile Number
					<input type="tel" placeholder="Please enter your mobile" v-model={contactInformation.mobileNumber} />
				</label>

				<label>
					<Required label="How many properties do you own?" />
					<input type="number" v-model={data.propertiesOwned} min={1} max={100000} required />
				</label>
			</div>
		</BasicStep>
	)
}

export default defineComponent(LandlordInformationStep)
