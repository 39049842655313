import type { CustomerEstimateData } from "@/models"
import type { Component } from "vue-utils"

export type GotoStepInformation = EstimateStep | null | ((data: CustomerEstimateData) => EstimateStep | null)

export function getStepInformation(info: GotoStepInformation, estimate: CustomerEstimateData): EstimateStep | null {
	if (typeof info !== "function") {
		return info
	}
	return info(estimate)
}

export interface EstimateStep {
	id: string
	component: Component

	nextStep: GotoStepInformation
	previousStep: GotoStepInformation

	usePrimaryColor?: boolean
}
