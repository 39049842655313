import { renderSlot, type ButtonHTMLAttributes } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import type { Props as BootstrapButtonProps } from "./BootstrapButton"
import BootstrapButton from "./BootstrapButton"

interface Props extends BootstrapButtonProps {
	icon: string
}

const IconButton: ReactiveComponent<Props, ButtonHTMLAttributes> = (props, { slots, attrs }) => {
	return () => (
		<BootstrapButton {...props} {...attrs}>
			<i class={props.icon} />
			{renderSlot(slots, "default")}
		</BootstrapButton>
	)
}

export default defineComponent(IconButton, {
	icon: requiredProp(String),
	color: requiredProp(String),
})
