import FullScreenLoading from "@/components/FullScreenLoading"
import { showModalMessage } from "@/modals"
import { h, ref } from "vue"
import { createLoadingHook, LoadingError, watchDebounce } from "vue-utils"

const { useLoading, runningActions } = createLoadingHook({
	loadingComponent: FullScreenLoading,
	showError: showModalMessage,
	coolInTimeMs: 100,
	defaultError: "Sorry, we could not contact the server. Please try again.",
})

const shouldShow = ref(false)
watchDebounce(
	runningActions,
	() => {
		shouldShow.value = runningActions.value > 0
	},
	250
)

function LoadingWrapper() {
	return shouldShow.value ? h(FullScreenLoading) : null
}

export { LoadingError, LoadingWrapper, useLoading }
