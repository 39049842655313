import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicStep from "../components/BasicStep"
import { onMounted, ref, toRef, watch } from "vue"
import { css } from "vite-css-in-js"
import { useCustomerEstimate } from "../composition"

const boxStyles = css`
	background-color: white;
	border-radius: 0.5rem;
	color: #333;
	box-shadow: #000 0.5rem 0.5rem 1rem -0.5rem;

	text-align: center;
	text-transform: uppercase;

	padding: 1.5rem;

	span {
		display: block;
	}

	span {
		display: block;
	}

	& > span:first-child {
		font-size: 2.5rem;
		font-weight: bolder;
	}
	& > span:nth-of-type(2) {
		margin-top: 1.5rem;
		line-height: 1.5;
		font-size: 1.5rem;
	}
`

const ShowEstimateStep: ReactiveComponent = () => {
	const data = useCustomerEstimate()
	const displayedValue = ref(0)

	let animationStart = -1
	let animationEnd = -1
	let animationRunning = false

	function runAnimation() {
		animationStart = Date.now()
		animationEnd = Date.now() + 2000
		if (animationRunning) {
			return
		}
		animationRunning = true
		updateAnimation()

		function updateAnimation() {
			if (!animationRunning) {
				return
			}
			if (Date.now() >= animationEnd) {
				animationRunning = false
				displayedValue.value = data.estimate ?? 0
				return
			}
			const now = Date.now()
			const percentageIn = (now - animationStart) / (animationEnd - animationStart)
			const timingPosition = 0.5 - Math.cos(percentageIn * Math.PI) / 2

			displayedValue.value = (data.estimate ?? 0) * timingPosition

			requestAnimationFrame(updateAnimation)
		}
	}

	onMounted(runAnimation)
	watch(toRef(data, "estimate"), runAnimation)

	return () => (
		<BasicStep title="Your Estimate" style={{ display: "flex", justifyContent: "center", "align-items": "center" }}>
			<div class={boxStyles}>
				<span>£{Math.floor(displayedValue.value)}</span>
				<span>Estimated Savings</span>
			</div>
		</BasicStep>
	)
}

export default defineComponent(ShowEstimateStep)
