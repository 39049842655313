import { type HTMLAttributes } from "vue"
import { defineComponent, optionalProp, renderSlot, requiredProp, type ReactiveComponent } from "vue-utils"
import StepNavigation from "./StepNavigation"

interface Props {
	title: string
	nextDisabled?: boolean | string
}

const BasicStep: ReactiveComponent<Props, HTMLAttributes> = (props, { slots, attrs }) => {
	return () => (
		<>
			<header>
				<h3>{props.title}</h3>
			</header>
			<main>
				<div {...attrs}>{renderSlot(slots)}</div>
			</main>
			<hr />
			<footer>
				<StepNavigation nextDisabled={props.nextDisabled} />
			</footer>
		</>
	)
}

export default defineComponent(BasicStep, {
	title: requiredProp(String),
	nextDisabled: optionalProp(String, Boolean),
})
