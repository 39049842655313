import { defineGlobals, LoadingError } from "vue-utils"
import type { ApiTypeOf } from "./models"

export enum RequestMethod {
	GET = "GET",
	POST = "POST",
	DELETE = "DELETE",
	PUT = "PUT",
}

export class HttpLoadingError extends LoadingError {
	readonly statusCode: number
	readonly statusText: string

	constructor(response: Response, message: string) {
		super(response.statusText, message)

		this.statusCode = response.status
		this.statusText = response.statusText
	}
}

export const httpRequest = async (url: string, method: RequestMethod, requestOptions?: RequestInit) => {
	const result = await fetch(url, {
		method,
		...requestOptions,
	})

	if (result.status === 400) {
		const err = await tryReadJsonMessage(result)
		throw new HttpLoadingError(result, err ?? "There was an issue with the data you submitted")
	}
	if (result.status >= 400 && result.status < 600) {
		const err = await tryReadJsonMessage(result)
		throw new HttpLoadingError(result, err ?? "An unexpected error occurred on our back end systems")
	}

	return result
}

const tryReadJsonMessage = async (result: Response): Promise<string | null> => {
	try {
		const json = (await result.json()) as { message?: string }
		return json.message ?? null
	} catch (e) {
		return null
	}
}

export const httpRequestJsonResponse = async <T>(
	url: string,
	method: RequestMethod,
	requestOptions?: RequestInit
): Promise<ApiTypeOf<T>> => {
	const result = await httpRequest(url, method, requestOptions)
	return (await result.json()) as ApiTypeOf<T>
}

export const httpJsonRequest = async (url: string, content: unknown, method: RequestMethod): Promise<Response> =>
	await httpRequest(url, method, {
		body: JSON.stringify(content),
		headers: {
			"content-type": "application/json",
		},
	})

export const httpJsonRequestJsonResponse = async <T>(
	url: string,
	content: unknown,
	method: RequestMethod
): Promise<ApiTypeOf<T>> => {
	const result = await httpJsonRequest(url, content, method)
	return (await result.json()) as ApiTypeOf<T>
}

defineGlobals({
	RequestMethod,
	httpRequest,
	httpJsonRequest,
	httpRequestJsonResponse,
	httpJsonRequestJsonResponse,
})
