import { onMounted, ref } from "vue"
import { anyProp, defineComponent, optionalProp, propsWithDefaults, type ReactiveComponent } from "vue-utils"
import type { ModalData } from "./types"
import type { Modal } from "bootstrap"
import { createModal } from "./createModal"

const BootstrapModal: ReactiveComponent<ModalData> = (initialProps) => {
	const props = propsWithDefaults(initialProps, {
		closeOnBackgroundClick: false,
	})
	const modalRef = ref<HTMLElement>()

	let modal: Modal | null = null

	onMounted(() => {
		const el = modalRef.value
		if (el) {
			modal = createModal(el, {
				backdrop: props.closeOnBackgroundClick ? true : "static",
				focus: true,
			})
			modal.show()
		}
	})

	function close() {
		modal?.hide()
		props.onClosed?.()
	}

	function renderTitle() {
		if (typeof props.title === "string") {
			return props.title
		}
		return <props.title close={close} />
	}

	function renderContent() {
		if (typeof props.content === "string") {
			return props.content
		}
		return <props.content close={close} />
	}

	function renderFooter() {
		if (!props.footer) {
			return (
				<button type="button" class="btn btn-secondary" onClick={close}>
					Close
				</button>
			)
		}
		if (typeof props.footer === "string") {
			return props.footer
		}
		return <props.footer close={close} />
	}

	return () => (
		<div ref={modalRef} class="modal fade">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{renderTitle()}</h5>
						{props.closeButton !== false && (
							<button type="button" class="btn-close" aria-label="Close" onClick={close} />
						)}
					</div>
					<div class="modal-body">{renderContent()}</div>
					<div class="modal-footer">{renderFooter()}</div>
				</div>
			</div>
		</div>
	)
}

export default defineComponent(BootstrapModal, {
	closeOnBackgroundClick: optionalProp(Boolean),
	closeButton: optionalProp(Boolean),
	title: anyProp(),
	content: anyProp(),
	footer: anyProp(),
	onClosed: optionalProp(Function),
})
