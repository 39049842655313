import { proxyProp } from "@/utils/proxyProp"
import { defineComponent, Required, type ReactiveComponent } from "vue-utils"
import { css } from "vite-css-in-js"
import BasicStep from "../components/BasicStep"
import { useCustomerEstimate } from "../composition"

const gridStyles = css`
	display: grid;
	grid-template-columns: auto auto;

	gap: 1.5rem;
	width: 100%;
	margin-top: 1.5rem;

	@media (max-width: 48rem) {
		grid-template-columns: auto;
		justify-content: stretch;
		align-items: center;

		label {
			align-items: stretch;
		}
	}
`

const ContactInformationStep: ReactiveComponent = () => {
	const data = useCustomerEstimate()
	const contactInformation = proxyProp(() => {
		return (data.contactInformation ??= {
			name: "",
			email: "",
			mobileNumber: null,
		})
	})

	return () => (
		<BasicStep title="Contact Information">
			<p>Please fill in your contact information so we can contact you about your estimate.</p>
			<div class={gridStyles}>
				<label>
					<Required label="Your name" />
					<input type="text" placeholder="Please enter your name" v-model={contactInformation.name} required />
				</label>

				<label>
					<Required label="Your Email" />
					<input type="email" placeholder="Please enter your email" v-model={contactInformation.email} required />
				</label>

				<label>
					Mobile Number
					<input type="tel" placeholder="Please enter your mobile number" v-model={contactInformation.mobileNumber} />
				</label>
			</div>
		</BasicStep>
	)
}

export default defineComponent(ContactInformationStep)
