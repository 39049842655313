import { lookupAddress, lookupPostcode } from "@/apps/calculator/api"
import IconButton from "@/components/IconButton"
import { useLoading } from "@/composition/useLoading"
import { showModalMessage } from "@/modals"
import type { PostcodeLookupEntry } from "@/models"
import { fixPostcode, isPostcodeValid } from "@/utils/postcode"
import { proxyProp } from "@/utils/proxyProp"
import { css } from "vite-css-in-js"
import { ref, shallowRef } from "vue"
import { Required, defineComponent, useOnInput, type ReactiveComponent } from "vue-utils"
import BasicStep from "../components/BasicStep"
import PostcodeInput from "../components/PostcodeInput"
import { useCustomerEstimate } from "../composition"
import TextButton from "@/components/TextButton"
import { useCustomValidation } from "@/composition/useCustomValidation"

const searchButtonStyles = css`
	@media (max-width: 32rem) {
		& > span {
			display: none;
		}
	}
`

const inputGroupStyes = css`
	gap: 0.25rem;

	@media (min-width: calc(40rem + 0.01px)) {
		display: grid;
		grid-template-columns: 10rem 1fr;
		align-items: stretch;
		gap: 0.75rem 0;

		& > span {
			display: flex;
			align-items: center;
			font-size: 1rem;
			font-weight: 400;
			color: #495057;
			text-align: center;
			white-space: nowrap;
			background-color: #e9ecef;
			border: 1px solid #aaaaaa;
			border-radius: 0.5rem;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			padding: 0.25rem 0.5rem;
		}
		input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-left: none;
		}
	}
`

const AddressStep: ReactiveComponent = () => {
	const data = useCustomerEstimate()
	const address = proxyProp(() => {
		if (data.address) {
			return data.address
		}
		data.address = {
			line1: "",
			line2: null,
			line3: null,
			line4: null,
			county: null,
			postcode: "",
		}
		return data.address
	})
	const options = shallowRef<PostcodeLookupEntry[]>([])
	const selectedId = ref<string>("")
	const expanded = ref(address.line1.trim().length > 0)

	const { runAction } = useLoading()

	async function searchPostcode() {
		address.postcode = fixPostcode(address.postcode.trim())

		const addresses = await runAction(lookupPostcode(address.postcode))
		options.value = addresses
		selectedId.value = "_"

		address.line1 = ""
		address.line2 = null
		address.line3 = null
		address.line4 = null
		address.county = ""

		if (addresses.length === 0) {
			await showModalMessage(
				"Postcode search",
				"No addresses could be found for the postcode you supplied. Please ensure you have provided a valid UK RESIDENTIAL Postcode."
			)
			return
		}
	}

	async function loadAddress(id: string) {
		if (id === "") {
			return
		}
		const fullAddress = await runAction(lookupAddress(id))
		options.value = []
		expanded.value = true

		Object.assign(address, fullAddress)
	}

	function handlePostcodeKeyPress(e: KeyboardEvent) {
		if (e.key === "Enter") {
			const target = e.target as HTMLInputElement
			e.preventDefault()
			e.stopImmediatePropagation()

			if (isPostcodeValid(address.postcode.trim())) {
				target.blur()
				void searchPostcode()
			} else if (address.postcode.trim().length > 0) {
				void showModalMessage("Invalid postcode", "Please enter a valid postcode")
			}
		}
	}

	useCustomValidation(() => {
		if (address.line1.trim().length === 0 || !isPostcodeValid(address.postcode)) {
			return "Please ensure you have entered a valid postal address"
		}
		return true
	})

	return () => (
		<BasicStep title="Your Address" class="flex flex-col spacing-4">
			<p>
				Please provide us with your <strong>home</strong> address.
			</p>

			{expanded.value && (
				<>
					<label key="line-1" class={inputGroupStyes}>
						<span>
							<Required label="Address Line 1" />
						</span>
						<input type="text" autocomplete="street-address" v-model={address.line1} required />
					</label>
					<label key="line-2" class={inputGroupStyes}>
						<span>Address Line 2</span>
						<input type="text" autocomplete="street-address" v-model={address.line2} />
					</label>
					<label key="line-3" class={inputGroupStyes}>
						<span>Address Line 3</span>
						<input type="text" autocomplete="street-address" v-model={address.line3} />
					</label>
					<label key="line-4" class={inputGroupStyes}>
						<span>Address Line 4</span>
						<input type="text" autocomplete="street-address" v-model={address.line4} />
					</label>
					<label key="county" class={inputGroupStyes}>
						<span>County</span>
						<input type="text" autocomplete="street-address" v-model={address.county} />
					</label>
				</>
			)}

			<label key="postcode">
				{!expanded.value && <>Find your address by entering your postcode:</>}
				<div class="flex items-stretch spacing-2">
					<div class={["flex-1", expanded.value ? inputGroupStyes : ""]}>
						{expanded.value && (
							<span>
								<Required label="Postcode" />
							</span>
						)}
						<div class="flex items-stretch spacing-2">
							<PostcodeInput
								type="search"
								class="flex-1"
								placeholder="Enter your home postcode"
								postcode={address.postcode}
								setPostcode={(postcode) => {
									if (address.postcode !== postcode) {
										options.value = []
										selectedId.value = "_"
									}
									address.postcode = postcode
								}}
								onKeypress={handlePostcodeKeyPress}
								required
							/>
							<IconButton
								type="button"
								class={searchButtonStyles}
								color="primary"
								icon="fa-solid fa-magnifying-glass"
								onClick={() => void searchPostcode()}
								disabled={!isPostcodeValid(address.postcode)}
							>
								<span style={{ whiteSpace: "nowrap" }}>Lookup Address</span>
							</IconButton>
						</div>
					</div>
				</div>
			</label>
			{options.value.length > 0 && (
				<label key="pick-address" class="w-full" style={{ gridColumnStart: "span 2" }}>
					{!expanded.value ? (
						<>Then pick your address from the list:</>
					) : (
						<>Please select your address from the list:</>
					)}
					<select v-model={selectedId.value} onInput={useOnInput((id) => void loadAddress(id))} required>
						<option key="" value="_" disabled>
							Please choose your address
						</option>
						{options.value.map((address) => (
							<option key={address.id} value={address.id}>
								{address.streetAddress}
							</option>
						))}
					</select>
				</label>
			)}

			{!expanded.value && (
				<span key="enter-manually" style={{ fontSize: "0.9rem" }}>
					Or if you'd prefer,{" "}
					<TextButton type="button" onClick={() => (expanded.value = true)}>
						enter your address manually
					</TextButton>
					.
				</span>
			)}
		</BasicStep>
	)
}

export default defineComponent(AddressStep)
