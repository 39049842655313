import { createApp } from "vue"
import type { AppInformation } from ".."
import HomeWerkzCalculator from "./HomeWerkzCalculator"
import { createPinia } from "pinia"
import { LoadingWrapper } from "@/composition/useLoading"
import { ModalsWrapper } from "@/modals"

const CalculatorAppInformation: AppInformation = {
	name: "Calculator",
	tryRegister: registerApp,
}

function registerApp() {
	const appElement = document.getElementById("hw-calculator")
	if (!appElement) {
		return false
	}

	const app = createApp({
		name: "CalculatorApp",
		render() {
			return (
				<>
					<HomeWerkzCalculator />
					<LoadingWrapper />
					<ModalsWrapper />
				</>
			)
		},
	})
	app.use(createPinia())
	app.mount(appElement)

	return true
}

export default CalculatorAppInformation
