import { RequestMethod, httpJsonRequestJsonResponse, httpRequestJsonResponse } from "@/httpService"
import type { CustomerEstimateData } from "@/models"

export async function updateEstimateData(estimate: CustomerEstimateData): Promise<CustomerEstimateData> {
	return await httpJsonRequestJsonResponse("/api/calculator/update", estimate, RequestMethod.PUT)
}

export async function createBlankEstimate(): Promise<CustomerEstimateData> {
	return await httpRequestJsonResponse<CustomerEstimateData>("/api/calculator/create-blank", RequestMethod.GET)
}
