import { useService } from "@/composition/useService"
import { showAlertModal } from "@/modals"
import type { Nullable } from "@/models"
import { type InjectionKey } from "vue"
import { delay } from "vue-utils"

export const ValidationHandlerKey = Symbol("ValidationHandler") as InjectionKey<ValidationHandler>

export function useValidationHandler(): ValidationHandler {
	return useService(ValidationHandlerKey)
}

export type InputValidation = () => void | Promise<void>
export type CustomCheck = () => boolean | Promise<boolean>

export type ValidationResult = string | boolean
export type CustomValidation = () => ValidationResult | Promise<ValidationResult>

export class ValidationHandler {
	private readonly inputValidations = new Map<symbol, InputValidation>()
	private readonly validations = new Map<symbol, CustomValidation>()
	private readonly customChecks = new Map<symbol, CustomCheck>()

	addInputValidation(validation: InputValidation) {
		const symbol = Symbol()
		this.inputValidations.set(symbol, validation)
		return symbol
	}

	private async runInputValidations() {
		for (const validation of this.inputValidations.values()) {
			await validation()
		}
	}

	removeInputValidation(key: symbol) {
		return this.inputValidations.delete(key)
	}

	addCustomValidation(validation: CustomValidation): symbol {
		const symbol = Symbol()
		this.validations.set(symbol, validation)
		return symbol
	}

	private async runCustomValidations(): Promise<ValidationResult[]> {
		const result: ValidationResult[] = []
		for (const check of this.validations.values()) {
			result.push(await check())
		}
		return result
	}

	removeCustomValidation(key: symbol) {
		return this.validations.delete(key)
	}

	addCustomCheck(check: CustomCheck) {
		const symbol = Symbol()
		this.customChecks.set(symbol, check)
		return symbol
	}

	private async runCustomChecks(): Promise<boolean> {
		for (const check of this.customChecks.values()) {
			if (!(await check())) {
				return false
			}
		}
		return true
	}

	removeCustomCheck(key: symbol) {
		return this.customChecks.delete(key)
	}

	async validateForm(form: Nullable<HTMLFormElement>) {
		if (form) {
			await this.runInputValidations()

			if (!form.reportValidity()) {
				return false
			}
		}

		const validationResults = await this.runCustomValidations()

		let failedValidations = 0
		const errorMessages: string[] = []

		for (const result of validationResults) {
			if (typeof result === "string" && result.length > 0) {
				failedValidations++
				errorMessages.push(result)
			} else if (result === false) {
				failedValidations++
			}
		}

		if (errorMessages.length > 0) {
			await showAlertModal({
				title: "Missing information",
				content: () => (
					<ul style={{ margin: 0, listStyle: "none", padding: 0 }}>
						{errorMessages.map((msg, i) => (
							<li key={i}>{msg}</li>
						))}
					</ul>
				),
			})
			await delay(250)
			return false
		}

		if (failedValidations > 0) {
			return false
		}

		return await this.runCustomChecks()
	}
}
