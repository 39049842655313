import type { AppInformation } from ".."

const AdminSystemAppInformation: AppInformation = {
	name: "HomeWerkzAdmin",
	tryRegister: registerApp,
}

function registerApp(): boolean {
	const appElement = document.getElementById("hw-admin")
	if (!appElement) {
		return false
	}

	// eslint-disable-next-line promise/prefer-await-to-then
	void import("./HomeWerkzAdmin").then((result) => result.createAdminApp(appElement))

	return true
}

export default AdminSystemAppInformation
