import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import LoadingSpinner from "./LoadingSpinner"

const loadingStyles = css`
	position: fixed;
	inset: 0;
	color: white;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 5;
	font-size: 4rem;

	display: flex;
	justify-content: center;

	& > * {
		margin-top: 15%;
	}
`

const FullScreenLoading: ReactiveComponent = () => {
	return () => (
		<div class={loadingStyles}>
			<LoadingSpinner />
		</div>
	)
}

export default defineComponent(FullScreenLoading)
