import { renderSlot, type ButtonHTMLAttributes } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

export type BootstrapButtonType = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark"

export interface Props {
	color: BootstrapButtonType
}

const BootstrapButton: ReactiveComponent<Props, ButtonHTMLAttributes> = (props, { slots, attrs }) => {
	return () => (
		<button class={["btn", `btn-${props.color}`, "flex", "justify-center", "items-center", "spacing-2"]} {...attrs}>
			{renderSlot(slots, "default")}
		</button>
	)
}

export default defineComponent(BootstrapButton, {
	color: requiredProp(String),
})
