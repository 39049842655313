import { getPropertyTypeDisplayName, PropertyType } from "@/models"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicStep from "../components/BasicStep"
import RadioButtons, { Orientation, type RadioOption } from "../components/RadioButtons"
import { useCustomerEstimate } from "../composition"

const PropertyTypeStep: ReactiveComponent = () => {
	const estimate = useCustomerEstimate()
	const options = computed<RadioOption<PropertyType>[]>(() =>
		Object.values(PropertyType).map((type) => ({
			data: type,
			value: type,
			text: getPropertyTypeDisplayName(type),
		}))
	)

	return () => (
		<BasicStep title="What type of property do you live in?">
			<RadioButtons
				name="owner-status"
				orientation={Orientation.Portrait}
				options={options.value}
				value={estimate.propertyType}
				setValue={(type) => (estimate.propertyType = type)}
				required
			>
				<p>Please tell us what type of property you live in:</p>
			</RadioButtons>
		</BasicStep>
	)
}

export default defineComponent(PropertyTypeStep)
