//Import the modal class directly from the bootstrap source, avoiding unnecessary extra imports which bootstrap bundles by default

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import BModalClass from "bootstrap/js/src/modal"
import type { Modal } from "bootstrap"

export function createModal(element: string | Element, options?: Partial<Modal.Options>): Modal {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	return new BModalClass(element, options) as Modal
}
