import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicStep from "../components/BasicStep"
import YesNoUnsureOption from "../components/YesNoUnsureOption"
import { useCustomerEstimate } from "../composition"

const listStyles = css`
	all: unset;
	li {
		all: unset;
	}

	display: grid;
	grid-template-columns: 1fr 1fr;

	gap: 1.5rem;
	width: 100%;

	@media (max-width: 48rem) {
		grid-template-columns: 1fr;
	}
`

const EfficiencyInstallationsStep: ReactiveComponent = () => {
	const data = useCustomerEstimate()

	return () => (
		<BasicStep title="Efficiency Installations">
			<p>Does your property have any of the following? Select all that apply.</p>

			<ul class={listStyles}>
				{data.efficiencyInstallations.map((installation) => (
					<li key={installation.type}>
						<YesNoUnsureOption
							name={`installation-${installation.type.toLowerCase()}`}
							value={installation.installed}
							setValue={(v) => (installation.installed = v)}
							required
						>
							{installation.displayName}
						</YesNoUnsureOption>
					</li>
				))}
			</ul>
		</BasicStep>
	)
}

export default defineComponent(EfficiencyInstallationsStep)
