import { computed } from "vue"
import { defineComponent, optionalProp, type ReactiveComponent } from "vue-utils"
import { useStepHandler } from "../StepHandler"

interface Props {
	nextDisabled?: boolean | string
}

const StepNavigation: ReactiveComponent<Props> = (props) => {
	const stepHandler = useStepHandler()

	const nextStep = computed(() => stepHandler.nextStep)
	const previousStep = computed(() => stepHandler.previousStep)

	return () => (
		<div class="flex justify-center" style={{ gap: "1rem 3rem" }}>
			{!!previousStep.value && (
				<button class="btn btn-light" type="button" onClick={() => stepHandler.goBack()}>
					{"<"} Back
				</button>
			)}
			{!!nextStep.value && (
				<button
					class={["btn btn-light", props.nextDisabled ? "disabled" : ""]}
					type="submit"
					title={typeof props.nextDisabled === "string" ? props.nextDisabled : undefined}
				>
					Next {">"}
				</button>
			)}
		</div>
	)
}

export default defineComponent(StepNavigation, {
	nextDisabled: optionalProp(String, Boolean),
})
