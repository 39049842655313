export enum PropertyType {
	Detached = "Detached",
	Semi = "Semi",
	MidTerrace = "MidTerrace",
	Bungalow = "Bungalow",
	Flat = "Flat",
}

const displayNames: Record<PropertyType, string> = {
	[PropertyType.Detached]: "Detached",
	[PropertyType.Semi]: "Semi / End Terrace",
	[PropertyType.MidTerrace]: "Mid Terraced",
	[PropertyType.Bungalow]: "Bungalow",
	[PropertyType.Flat]: "Flat / Apartment",
}

export function getPropertyTypeDisplayName(type: PropertyType) {
	return displayNames[type]
}
