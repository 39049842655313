import { onMounted, onUnmounted, unref, type MaybeRef } from "vue"
import { useValidationHandler, type InputValidation } from "./ValidationHandler"

export type InputValidationResult = string | true

/**
 * Registers a validation to be run against the specified element when the current form is submitted.
 * Returning a string from the validation will show the specified error next to the element.
 *
 * @param element Either the element, or reference to the element, to check validity on
 * @param validate Validation to be run
 */
export function useInputValidation<T extends { setCustomValidity(message: string): void } & EventTarget>(
	element: MaybeRef<T | undefined> | T,
	validate: (element: T) => InputValidationResult | Promise<InputValidationResult>
) {
	async function checkValidity() {
		const elementValue = unref(element)
		if (elementValue) {
			const rawResult = await validate(elementValue)
			const errorMessage = rawResult === true ? "" : rawResult.trim()

			elementValue.setCustomValidity(errorMessage)
		}
	}

	return useCustomInputValidation(checkValidity)
}

/**
 * Registers a validation to be run against elements on the form when the user attempts to move onto the next step.
 * Callers should run `setCustomValidity` on any required HTML input elements
 *
 * @param validation Validation code to be run
 */
export function useCustomInputValidation(validation: InputValidation) {
	const handler = useValidationHandler()

	let symbol: symbol
	onMounted(() => (symbol = handler.addInputValidation(validation)))
	onUnmounted(() => handler.removeInputValidation(symbol))
}
