import { PropertyOwnerStatus } from "@/models"
import LandlordInformationStep from "./LandlordInformationStep"
import PropertyOwnerStep from "./PropertyOwnerStep"
import ReceivedBenefitsStep from "./ReceivedBenefitsStep"
import type { EstimateStep } from "./types"
import EfficiencyInstallationsStep from "./EfficiencyInstallationsStep"
import PropertyTypeStep from "./PropertyTypeStep"
import ContactInformationStep from "./ContactInformationStep"
import ShowEstimateStep from "./ShowEstimateStep"
import AddressStep from "./AddressStep"

const PropertyOwner: EstimateStep = {
	id: "property-owner",
	component: PropertyOwnerStep,
	nextStep: (data) => (data.ownerStatus === PropertyOwnerStatus.Landlord ? LandlordInformation : ReceivedBenefits),
	previousStep: null,
}

const ReceivedBenefits: EstimateStep = {
	id: "received-benefits",
	component: ReceivedBenefitsStep,
	nextStep: () => EfficiencyInstallations,
	previousStep: () => PropertyOwner,
}

const LandlordInformation: EstimateStep = {
	id: "landlord-information",
	component: LandlordInformationStep,
	previousStep: () => PropertyOwner,
	nextStep: () => null,
}

const EfficiencyInstallations: EstimateStep = {
	id: "efficiency-installations",
	component: EfficiencyInstallationsStep,
	previousStep: () => ReceivedBenefits,
	nextStep: () => PropertyType,
}

const PropertyType: EstimateStep = {
	id: "property-type",
	component: PropertyTypeStep,
	previousStep: () => EfficiencyInstallations,
	nextStep: () => ContactDetails,
}

const ContactDetails: EstimateStep = {
	id: "contact",
	component: ContactInformationStep,
	previousStep: () => PropertyType,
	nextStep: () => Address,
}

const Address: EstimateStep = {
	id: "address",
	component: AddressStep,
	previousStep: () => ContactDetails,
	nextStep: () => ShowEstimate,
}

const ShowEstimate: EstimateStep = {
	id: "show-estimate",
	component: ShowEstimateStep,
	previousStep: () => Address,
	nextStep: () => null,
	usePrimaryColor: true,
}

export const Steps = {
	InitialStep: PropertyOwner,
	PropertyOwner,
	ReceivedBenefits,
	LandlordInformation,
	EfficiencyInstallations,
	PropertyType,
	ContactDetails,
	Address,
	ShowEstimate,
}
