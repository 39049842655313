import { type InputHTMLAttributes } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import RadioButtons, { Orientation, type RadioOption } from "./RadioButtons"
import { UnsureBool } from "@/models/UnsureBool"

interface Props {
	text?: string

	name: string
	value: UnsureBool | null | undefined
	setValue(value: UnsureBool): void

	required?: boolean
	disabled?: boolean
}

const YesNoUnsureOption: ReactiveComponent<Props, InputHTMLAttributes> = (props, { slots, attrs }) => {
	const options: RadioOption<UnsureBool>[] = [
		{
			value: true,
			data: UnsureBool.Yes,
			text: "Yes",
		},
		{
			value: false,
			data: UnsureBool.No,
			text: "No",
		},
		{
			value: null,
			data: UnsureBool.Unsure,
			text: "Unsure",
		},
	]

	return () => (
		<RadioButtons
			name={props.name}
			orientation={Orientation.Landscape}
			options={options}
			value={props.value}
			setValue={props.setValue}
			required={props.required}
			disabled={props.disabled}
			v-slots={slots}
			{...attrs}
		/>
	)
}

export default defineComponent(YesNoUnsureOption, {
	text: optionalProp(String),

	name: requiredProp(String),
	value: requiredProp(String, null, undefined),
	setValue: requiredProp(Function),

	required: optionalProp(Boolean),
	disabled: optionalProp(Boolean),
})
