import type { CustomerEstimateData } from "@/models"
import { onMounted, onUnmounted, provide, ref, shallowRef, watch } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import LandingPage from "./LandingPage"
import { StepHandler, StepHandlerKey } from "./StepHandler"
import { ValidationHandler, ValidationHandlerKey } from "../../composition/ValidationHandler"
import { CustomerEstimateDataKey } from "./composition"
import "./styles.scss"

const HomeWerkzCalculator: ReactiveComponent = () => {
	const formRef = shallowRef<HTMLFormElement>()
	const estimateData = shallowRef<CustomerEstimateData | null>(null)
	const percentageCompleted = ref(0)

	const validationHandler = new ValidationHandler()
	const stepHandler = new StepHandler(estimateData, formRef, validationHandler)

	provide(CustomerEstimateDataKey, estimateData)
	provide(ValidationHandlerKey, validationHandler)
	provide(StepHandlerKey, stepHandler)

	function renderContent() {
		if (!estimateData.value) {
			return <LandingPage />
		}

		const step = stepHandler.currentStep
		if (step) {
			return <step.component />
		}
		return <LandingPage />
	}

	function handleFormSubmit(e: Event) {
		e.preventDefault()

		if (stepHandler.hasEstimateData) {
			void stepHandler.goToNextStep()
		} else {
			void stepHandler.loadInitialData()
		}
	}

	watch(
		() => stepHandler.currentStep,
		(newStep) => {
			if (!newStep) {
				percentageCompleted.value = 0
			} else {
				const next = stepHandler.countNextSteps()
				const previous = stepHandler.countPreviousSteps()

				percentageCompleted.value = (previous + 1) / (previous + next + 1)
			}
		}
	)

	if (localStorage.getItem("estimate-data")) {
		stepHandler.estimateData = JSON.parse(localStorage.getItem("estimate-data") as string) as CustomerEstimateData
	}

	onMounted(() => {
		Object.defineProperty(window, "estimateData", {
			get() {
				return estimateData.value
			},
			set(newValue: CustomerEstimateData | null) {
				stepHandler.estimateData = newValue
			},
		})
	})
	onUnmounted(() => {
		const windowAs = window as unknown as Record<string, unknown>
		delete windowAs.estimateData
	})

	return () => (
		<form
			ref={formRef}
			data-primary={String((stepHandler.currentStep === null || stepHandler.currentStep.usePrimaryColor) ?? false)}
			class="hw-calculator"
			onSubmit={handleFormSubmit}
			style={!stepHandler.currentStep ? { height: "32rem" } : {}}
			novalidate
		>
			{renderContent()}
			{!!stepHandler.currentStep && (
				<div class="progress w-full" style={{ borderRadius: 0 }}>
					<div
						class={["progress-bar progress-bar-striped"]}
						role="progressbar"
						style={{ width: `${percentageCompleted.value * 100}%`, backgroundColor: "#628cdd" }}
						aria-valuenow={percentageCompleted.value * 100}
						aria-valuemin="0"
						aria-valuemax="100"
						aria-label="Calculator progress bar"
					/>
				</div>
			)}
		</form>
	)
}

export default defineComponent(HomeWerkzCalculator)
