import type { CustomerEstimateData } from "@/models"
import { inject, type InjectionKey, type ShallowRef } from "vue"

export const CustomerEstimateDataKey = Symbol("customerEstimate") as InjectionKey<
	ShallowRef<CustomerEstimateData | null>
>

export function useCustomerEstimateRef(): ShallowRef<CustomerEstimateData | null> {
	const ref = inject(CustomerEstimateDataKey)
	if (ref === undefined) {
		throw new Error("Estimate ref has not been configured")
	}
	return ref
}

export function useCustomerEstimate(): CustomerEstimateData {
	const data = useCustomerEstimateRef().value
	if (data === null) {
		throw new Error("Estimate data has not yet loaded")
	}
	return data
}
