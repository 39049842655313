import { css } from "vite-css-in-js"
import { renderSlot, type InputHTMLAttributes, type SetupContext } from "vue"
import { anyProp, optionalProp, requiredProp } from "vue-utils"

interface Props<T> {
	options: RadioOption<T>[]

	value: T | null | undefined
	setValue(value: T): void

	text?: string
	name: string
	orientation: Orientation

	disabled?: boolean
	required?: boolean
}

export interface RadioOption<T> {
	data: T
	value: unknown
	text: string
}

export enum Orientation {
	Landscape = "landscape",
	Portrait = "portrait",
}

const styles = css`
	display: flex;
	flex-direction: column;

	legend {
		font-size: 1rem;
		margin-bottom: 0.125rem;
		font-weight: 500;
	}

	label {
		flex-direction: row;
		align-items: center;
	}

	& > div {
		display: flex;
		gap: 1.5rem 1rem;
	}

	&[data-orientation="portrait"] {
		& > div {
			flex-direction: column;
		}
	}
`

function RadioButtons<T>(props: Props<T> & InputHTMLAttributes, { slots, attrs }: SetupContext) {
	return (
		<fieldset class={styles} data-orientation={props.orientation}>
			{(props.text || "default" in slots) && <legend>{props.text ? props.text : renderSlot(slots, "default")}</legend>}
			<div>
				{props.options.map((option) => (
					<label key={String(option.value)}>
						<input
							type="radio"
							name={props.name}
							value={option.value}
							class={["form-check-input", props.required && "required"]}
							required={props.required}
							disabled={props.disabled}
							checked={props.value === option.data}
							onInput={() => props.setValue(option.data)}
							{...attrs}
						/>
						<span>{option.text}</span>
					</label>
				))}
			</div>
		</fieldset>
	)
}

RadioButtons.props = {
	options: requiredProp(Array),

	value: anyProp(),
	setValue: requiredProp(Function),

	text: optionalProp(String),
	name: requiredProp(String),
	orientation: requiredProp(String),

	disabled: optionalProp(Boolean),
	required: optionalProp(Boolean),
}

export default RadioButtons
