import { defineComponent, type ReactiveComponent } from "vue-utils"

const LandingPage: ReactiveComponent = () => {
	return () => (
		<>
			<header>
				<h3>
					<span>Instant Estimate</span>
				</h3>
			</header>

			<main class="flex items-center">
				<p>Find out how much you could save!</p>
				<p>££££££</p>

				<button class="btn btn-light" type="submit">
					Get Started
				</button>
			</main>
		</>
	)
}

export default defineComponent(LandingPage)
