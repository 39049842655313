import { css } from "vite-css-in-js"
import type { ButtonHTMLAttributes } from "vue"
import { defineComponent, renderSlot, type NoProps, type ReactiveComponent } from "vue-utils"

const textButtonStyles = css`
	all: unset;
	display: inline;
	cursor: pointer;
	text-decoration: underline;

	&:hover {
		text-decoration: underline;
	}
`

const TextButton: ReactiveComponent<NoProps, ButtonHTMLAttributes> = (_, { attrs, slots }) => {
	return () => (
		<button class={textButtonStyles} {...attrs}>
			{renderSlot(slots)}
		</button>
	)
}

export default defineComponent(TextButton)
